import React from "react";

const Footer = () => {
  return (
    <>
      <div
        className="container-fluid footer bg-dark wow fadeIn"
        data-wow-delay=".3s"
      >
        <div className="container pt-5 pb-4">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <p>
                <h1 className="text-white fw-bold d-block">
                  Vital Tech <span className="text-secondary">Solutions</span>{" "}
                  Pvt Ltd
                </h1>
              </p>
              <p className="mt-4 text-light" style={{ fontSize: "13px" }}>
                At VitalTech Solutions Ltd, we specialize in empowering
                businesses with cutting-edge design and development solutions,
                tailored branding strategies, and impactful social media
                marketing campaigns.
              </p>
              <div className="d-flex hightech-link">
                <a
                  href="https://www.facebook.com/vitaltechsolutionspvtltd/"
                  className="btn-dark nav-fill btn btn-square rounded-circle me-2"
                >
                  <i className="fab fa-facebook-f text-secondary"></i>
                </a>
                <a
                  href="https://twitter.com/vitaltechpvtltd"
                  className="btn-dark nav-fill btn btn-square rounded-circle me-2"
                >
                  <i className="fab fa-twitter text-secondary"></i>
                </a>
                <a
                  href="https://www.instagram.com/vitaltechsolutionspvtltd/"
                  className="btn-dark nav-fill btn btn-square rounded-circle me-2"
                >
                  <i className="fab fa-instagram text-secondary"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/vitaltechsolutionspvtltd/"
                  className="btn-dark nav-fill btn btn-square rounded-circle me-0"
                >
                  <i className="fab fa-linkedin-in text-secondary"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <p className="h3 text-secondary">Short Link</p>
              <div className="mt-4 d-flex flex-column short-link">
                <a href="/about" className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>
                  About us
                </a>
                <a href="/contact" className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>
                  Contact us
                </a>
                <a href="/services" className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>Our
                  Services
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <p className="h3 text-secondary">Help Link</p>
              <div className="mt-4 d-flex flex-column help-link">
                <p className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>
                  Helps
                </p>
                <p className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>FAQs
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <p className="h3 text-secondary">Contact Us</p>
              <div className="text-white mt-4 d-flex flex-column contact-link">
                <a
                  href="https://maps.app.goo.gl/cTrvqRHg4MaHqFTW6"
                  className="pb-3 text-light  "
                >
                  <i className="fas fa-map-marker-alt text-secondary me-2"></i>{" "}
                  F-24, A2, Beverly Estate, Block 9 Clifton, Karachi, Karachi City, Sindh 75600
                </a>
                <a href="tel:+923342658105" className="py-3 text-light ">
                  <i className="fas fa-phone-alt text-secondary me-2"></i>+92
                  334 2658105
                </a>
                <a
                  href="mailto:info@vitaltechsolutionspvtltd.com"
                  className="py-3 text-light "
                >
                  <i className="fas fa-envelope text-secondary me-2"></i>{" "}
                  info@vitaltechsolutionspvtltd.com
                </a>
              </div>
            </div>
          </div>
          <hr className="text-light mt-5 mb-4" />
          <div className="row">
            <div className="col-md-12 text-center ">
              <span className="text-light">
                <a href="/" className="text-secondary">
                  &copy;&nbsp;
                </a>
                Vital Tech Solutions PVT LTD , All right reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
